export function pathToLink(path) {
  return path.replace(process.env.GATSBY_WP_DIRECTORY, "");
}

export function urlToLink(path) {
  const clean = path.replace(process.env.GATSBY_WP_URL, "");
  if (clean === "/") return "/";
  return clean.includes("http")
    ? clean
    : "/" + path.replace(process.env.GATSBY_WP_URL, "");
}

export function removeHtml(string) {
  return string ? string.replace(/(<([^>]+)>)/gi, "") : string;
}
