import { useStaticQuery, graphql } from "gatsby";

import React from "react";
import { Helmet } from "react-helmet";
import { get, truncate } from "lodash";
import he from "he";

import SchemaSeo from "./SchemaSeo";
import { removeHtml } from "../helper";

const defaultImage = `https://dreamsintercambios.com.br/static/1577c8186c0ccfb53c578a0f57c644ad/2b037/logo.webp`;

//https://www.url-encode-decode.com/
// const postImage = (title, background) =>
//   `https://og-image-clubedacarlota.now.sh/${encodeURIComponent(
//     he.decode(title)
//   )}.jpeg?theme=dark&md=1&fontSize=100px&images=${encodeURIComponent(
//     background
//   )}`;

function SEO({
  description,
  lang = "pt-BR",
  meta,
  keywords = [],
  title,
  image: metaImage,
  article,
  pathname = ""
}) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          verifications {
            name
            content
          }
          title
          description
          author
          defaultKeywords
          siteUrl
          siteName
          organization {
            name
            logo
            url
          }
        }
      }
    }
  `);

  const {
    author,
    siteName,
    defaultKeywords,
    siteUrl,
    organization,
    verifications
  } = site.siteMetadata;

  const seo = {
    title: he.decode(title || site.siteMetadata.title),
    description: truncate(
      removeHtml(description || site.siteMetadata.description),
      {
        length: 157
      }
    ),
    image: metaImage ? metaImage : defaultImage,
    keywords: [...defaultKeywords, ...keywords],
    url: pathname ? `${siteUrl}/${pathname}/` : siteUrl
  };

  return (
    <>
      <Helmet
        title={seo.title}
        htmlAttributes={{
          lang
        }}
      >
        {/* <link rel="canonical" href={seo.url} /> */}
        <meta property="keywords" content={seo.keywords.join(`, `)} />
        <meta name="description" content={seo.description} />

        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:locale" content="pt-BR" />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:width" content="1380" />
        <meta property="og:image:height" content="788" />
        <meta property="fb:app_id" content="830459366984475" />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:url" content={seo.url} />

        {article ? (
          <meta property="og:type" content="article" />
        ) : (
          <meta property="og:type" content="website" />
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={seo.image} />

        {verifications &&
          verifications.map(node => (
            // eslint-disable-next-line react/jsx-key
            <meta name={node.name} content={node.content} />
          ))}
      </Helmet>

      <SchemaSeo
        url={seo.url}
        title={seo.title}
        image={seo.image}
        description={seo.description}
        siteUrl={siteUrl}
        defaultTitle={site.siteMetadata.title}
        defaultDescription={site.siteMetadata.description}
        isBlogPost={!!article}
        datePublished={get(article, "datePublished", new Date().toDateString())}
        dateModified={get(article, "dateModified", new Date().toDateString())}
        author={get(article, "author", author)}
        organization={organization}
        lang={lang}
      />
    </>
  );
}

export default SEO;
