import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { get } from "lodash";
import Img from "gatsby-image";

import { urlToLink } from "../helper";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const { file, blog } = useStaticQuery(graphql`
    query SiteTitleQuery {
      file(name: { eq: "logo" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality: 80, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog {
        menus(where: { slug: "header" }) {
          nodes {
            id
            name
            slug
            menuItems {
              nodes {
                id
                url
                label
                isRestricted
              }
            }
          }
        }
      }
    }
  `);

  const headerMenu = get(blog, "menus.nodes[0].menuItems.nodes");

  return (
    <header className="bg-blue-100 text-blue-400">
      <div className="flex flex-wrap items-center justify-between p-4 mx-auto md:p-8 ">
        <Link to="/" className="no-underline block w-1/4 sm:w-24">
          <Img className="w-full" fluid={file.childImageSharp.fluid} />
        </Link>

        <button
          className="flex items-center block px-3 py-2  border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {headerMenu &&
            headerMenu.map(menu => (
              <Link
                className="block mt-4 uppercase hover:underline   md:inline-block md:mt-0 md:ml-6 md:text-lg"
                key={menu.label}
                to={`${urlToLink(menu.url)}/`}
              >
                {menu.label}
              </Link>
            ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
